class Slider {

    constructor(container) {

        this.container = container;
        this.initSlider(this.container);

    }


    initSlider(container) {
        console.log("init slider");

        let slider = new Swiper(container, {
            slidesPerView: 1,
            centeredSlides: true,
            speed: 600,
            spaceBetween: 20,
            pagination: {
                el: container.find('.swiper-pagination'),
                clickable: true,
            },
            navigation: {
                nextEl: container.find('.swiper-button-next'),
                prevEl: container.find('.swiper-button-prev'),
            },
        });
    }

}

export default Slider;
