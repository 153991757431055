class YouTube {

    constructor(container) {

        if ($('body').hasClass("privacy--allowed")) {
            this.init(container);
        }

    }


    init(container) {

        // The location of Uluru
        var uluru = {
            lat: -9.7906713,
            lng: 48.6817835
        };
        // The map, centered at Uluru
        var map = new google.maps.Map(
            document.getElementById('map'), {
                zoom: 4,
                center: uluru
            });
        // The marker, positioned at Uluru
        var marker = new google.maps.Marker({
            position: uluru,
            map: map
        });

    }
}

export default YouTube;
