// import drawSVG from './../vendor/gsap/DrawSVGPlugin';
import drawSVG from './../vendor/gsap/esm/DrawSVGPlugin';
// import 'gsap';

// Fix jquery.waypoints issue with this
window.jQuery = window.$ = require("jquery");

class MobileNav {


	constructor() {

		// Variables 
		this.header = $('header.header');
		this.menu = this.header.find('.nav');
		this.burger = this.menu.find('.nav__burger');
		this.curtain = this.menu.find('.nav__curtain');
		this.title = this.menu.find('.nav__title');

		// Timeline variables
		this.burgerTL = gsap.timeline();
		this.menuTL = gsap.timeline();

		// Initialize timeline animations
		this.init();
		this.events();

	}


	init() {

		let that = this;

		that.burger.css("opacity", "1");
		that.curtain.css("opacity", "1");
		that.title.css("opacity", "1");
		TweenLite.defaultEase = Power2.easeOut;

		// build menu animation
		let 
			// mobileWrapper = 	$('.nav-main-wrapper'),
			curtain = 	    $('.nav__curtain'),
			links = 		that.menu.find('.nav__link'),
			title = 		that.menu.find('.nav__title');

		that.menuTL
			// .to(mobileWrapper, 0.001, { css: {display: "block"} })
			.from(curtain, { duration: 0.4, autoAlpha: 0 }, "a")
			.from(title, { duration: 0.4, autoAlpha: 0 }, "a")
			// .staggerFrom(links, 0.2, { x: "+=15", autoAlpha: 0 }, 0.1, "b-=0.3")
			.pause();


		// build button animation
		let
			burgerSvg = 		that.burger.find('#nav__burger-svg'),
			lines = 			burgerSvg.find('#burger line'),
			closeUp = 			burgerSvg.find('#close line#up'),
			closeDown = 		burgerSvg.find('#close line#down');

		that.burgerTL
			.fromTo(lines, { duration: 0.1, x: "0", drawSVG: "0% 100%" }, { stagger: 0.1, x: "+=10", drawSVG: "0% 0%" })
			.fromTo(closeDown, { duration: 0.1, x: "-=10", y: "-=10", drawSVG: "100% 100%" }, { x: "0", y: "0", drawSVG: "0% 100%" }, "-=0.05")
			.fromTo(closeUp, { duration: 0.1, x: "-=10", y: "+=10", drawSVG: "100% 100%" }, { x: "0", y: "0", drawSVG: "0% 100%" }, "-=0.05")
			.set(that.menu, {className:"+=nav active"})
			.set(that.header, {className:"+=header active"})
			.set($('body'), {className:"+=overflow-hidden"})
			.pause();
	}


	events() {

		let that = this;

		// Burger Button Click
		that.burger.click(function(){
			that.toggleNavigation();
		});

		// Outside click
		// $('.a--page-curtain').click(function(){
		// 	that.toggleNavigation();
		// })
	}


	toggleNavigation() {

		let that = this;

		if(that.menu.hasClass("active")){
			// console.log("close menu");
			that.burgerTL.reverse();
			that.menuTL.reverse();
		} else {
			// console.log("open menu");
			that.burgerTL.play();
			that.menuTL.play();
		}

	}

}

export default MobileNav;