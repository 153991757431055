// Vendor

// Custom Modules
// import Images from './modules/images';
import MobileNav from './modules/mobileNav';
import simpleParallax from 'simple-parallax-js';
import Maps from './modules/maps';
import Slider from './modules/slider';
import Video from './modules/video';
import Scrollto from './modules/scrollto';
import Reveal from './modules/reveal';


// Handle Images
// if ($('img').length > 0) {
//     new Images($('img'));
// }

// Reveal contents by scrolling
if ($('.reveal').length > 0) {
    new Reveal($('.reveal'));
}

// Initialize mobile Navigation
$(window).ready(function () {
    new MobileNav();
});

// Initialize mobile Navigation
$(window).ready(function () {
    new Maps();
});

var image = document.getElementsByClassName('parallax--vertical');
new simpleParallax(image, {
    scale: 1.2,
    orientation: 'down',
});

var rellax = new Rellax('.rellax', {
    speed: -2,
    center: true,
});

if ($('.scrollto').length > 0) {
    new Scrollto($('.scrollto'));
}

// Initialize Swiper Slider
if ($('.referenzen__slider').length > 0) {
    new Slider($('.referenzen__slider'));
}


// Load youtube iframe api if there are any youtube videos on the page
if ($('.video').length > 0) {
    new Video($('.video'));
}


var lazyLoad = new LazyLoad({
    elements_selector: ".lazy",
    // More options here
});