// import gsap from 'gsap';
import 'debug.addIndicators';
// import '../vendor/gsap/animation.gsap';
import ScrollMagic from 'scrollmagic';

class Reveal {

    constructor(elems) {

        // Create scrollmagic controller
        this.controller = new ScrollMagic.Controller();

        // Create custom scrollmagic scenes and add them to the controller
        elems.each((k, v) => {
            let elem = $(v);
            // if (elem.hasClass("reveal--intro")) {
            //     this.intro(elem);
            // }
            // if (elem.hasClass("parallax--family")) {
            //     this.familyImgsScene(elem.find('.family__imgs'));
            //     this.familyLblScene(elem.find('.family__frost'));
            // }
            // if (elem.hasClass("parallax--video")) {
            //     this.videoScene(elem);
            // }
            // if (elem.hasClass("parallax--data")) {
            //     this.dataScene(elem);
            // }
            // if (elem.hasClass("parallax--sample")) {
            //     this.sampleScene(elem);
            // }
            // if (elem.hasClass("parallax--feature-check")) {
            //     this.featureCheckScene(elem);
            // }
            // if (elem.hasClass("parallax--features")) {
            //     this.featuresScene(elem);
            // }
            // if (elem.hasClass("parallax--solutions")) {
            //     this.solutionsScene(elem);
            // }
            // if (elem.hasClass("parallax--contact")) {
            //     this.contactScene(elem);
            // }
        });

    }


    intro(container) {

        let that = this;

        let tl = gsap.timeline();

        tl
            .to(container, {
                autoAlpha: 1,
                duration: 0
            })
            .from(container.find(".reveal__bg"), {
                x: "+100vw",
                duration: 1.2
            }, "+=0.3")
            .from(container.find(".teaser__img"), {
                y: "-100",
                opacity: 0,
                duration: 1.2
            })
            .from(container.find(".teaser__lbl > *"), {
                x: 100,
                opacity: 0,
                duration: 1.2
            }, "-=0.6");

    }



    familyImgsScene(container) {

        let that = this;

        let
            leftImg = container.find('.left'),
            rightImg = container.find('.right'),
            tl = new TimelineMax();

        tl
            .staggerFrom(leftImg, 1, {
                x: "-=50%"
            }, 0.5, "b")
            .staggerFrom(rightImg, 1, {
                x: "+=50%"
            }, 0.5, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "75%",
                offset: "-100%",
                // reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }

    familyLblScene(container) {

        let that = this;

        let
            label = container.find('.family__lbl'),
            tl = new TimelineMax();

        tl
            .from(label, 1, {
                x: "+=50",
                autoAlpha: 0
            }, "a")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-100%",
                // reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    seriesScene(container) {

        let that = this;

        let
            thumbs = container.find('.series-slider__thumbs .headline__container'),
            main = container.find('.series-slider__main'),
            leftButton = container.find('.swiper-button-prev'),
            rightButton = container.find('.swiper-button-next'),
            tl = new TimelineMax();

        tl
            .staggerFrom(thumbs, 1, {
                y: "+=50",
                autoAlpha: 0,
                clearProps: "all"
            }, 0.5, "a")
            .from(leftButton, 3, {
                x: "-=50",
                autoAlpha: 0
            }, "b")
            .from(rightButton, 3, {
                x: "+=50",
                autoAlpha: 0
            }, "b")
            .from(main, 3, {
                y: "+=25",
                autoAlpha: 0
            }, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-400%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    videoScene(container) {

        let that = this;

        let
            background = container.find('.family__video-background'),
            headline = container.find('.headline'),
            video = container.find('.video'),
            tl = new TimelineMax();

        tl
            .from(background, 2, {
                x: "-=100%"
            }, "a")
            .from(headline, 2, {
                x: "-=50",
                autoAlpha: 0
            }, "a")
            .from(video, 2, {
                x: "+=50%",
                autoAlpha: 0
            }, "a")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-300%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    dataScene(container) {

        let that = this;

        let
            headline = container.find('.headline'),
            column = container.find('.data__column'),
            tl = new TimelineMax();

        tl
            .from(headline, 2, {
                y: "-=50",
                autoAlpha: 0
            }, "a")
            .staggerFrom(column, 2, {
                y: "+=50",
                autoAlpha: 0,
                clearProps: "all"
            }, 0.5, "a+=0.5")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-300%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    sampleScene(container) {

        let that = this;

        let
            background = container.find('.sample__background'),
            headline = container.find('.headline'),
            content = container.find('.form__container'),
            tl = new TimelineMax();

        tl
            .from(background, 2, {
                x: "-=100%"
            }, "a")
            .from(headline, 2, {
                x: "-=50",
                autoAlpha: 0
            }, "b")
            .from(content, 2, {
                x: "+=50",
                autoAlpha: 0
            }, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-400%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    featureCheckScene(container) {

        let that = this;

        let
            headline = container.find('.headline'),
            checks = container.find('.features__check'),
            tl = new TimelineMax();

        tl
            .from(headline, 2, {
                x: "-=50",
                autoAlpha: 0
            }, "b")
            .staggerFrom(checks, 2, {
                x: "+=50",
                autoAlpha: 0
            }, 0.5, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-400%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    featuresScene(container) {

        let that = this;

        let
            headline = container.find('.headline'),
            feature = container.find('.feature'),
            tl = new TimelineMax();

        tl
            .from(headline, 2, {
                x: "-=50",
                autoAlpha: 0
            }, "b")
            .staggerFrom(feature, 2, {
                y: "+=50",
                autoAlpha: 0
            }, 0.5, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-400%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    solutionsScene(container) {

        let that = this;

        let
            item = container.find('.haccordion__item'),
            tl = new TimelineMax();

        tl
            .staggerFrom(item, 2, {
                y: "+=50",
                autoAlpha: 0
            }, 0.5, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-400%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }


    contactScene(container) {

        let that = this;

        let
            headline = container.find('.headline'),
            data = container.find('.contact__data'),
            downloads = container.find('.download'),
            tl = new TimelineMax();

        tl
            .from(headline, 2, {
                x: "-=50",
                autoAlpha: 0
            }, "b")
            .staggerFrom(data, 2, {
                x: "+=50",
                autoAlpha: 0
            }, 0.5, "b")
            .staggerFrom(downloads, 2, {
                x: "+=50",
                autoAlpha: 0
            }, 0.5, "b")

        // create a scene
        new ScrollMagic.Scene({
                triggerElement: container.get(0),
                duration: "50%",
                offset: "-200%",
                reverse: false
            })
            .setTween(tl)
            .addTo(that.controller)
        // .addIndicators();

    }
}

export default Reveal;
