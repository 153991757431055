// import Youtube from './youtube';

class Video {

    constructor(container) {

        let that = this;

        console.log(container);

        $(document).bind('privacyChanged', function () {

            $(document).ready(function () {

                console.log("ready 4 video");

                // Check if privacy allows youtube videos
                if ($('body').hasClass("privacy--allowed")) {

                    console.log("video allowed");

                    // Load youtube iframe api if there are any youtube videos on the page
                    loadYouTubeIframeAPI();

                    function loadYouTubeIframeAPI() {

                        // This code loads the IFrame Player API code asynchronously.
                        let tag = document.createElement('script');
                        tag.src = "https://www.youtube.com/iframe_api";
                        let firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                    }

                    // When Youtube Iframe API has successfully loaded
                    window.onYouTubeIframeAPIReady = function () {

                        console.log("🎉 onYouTubeIframeAPIReady 🎉");

                        // Init YouTube video
                        let videos = container;

                        // Avoid redundand videos in frost-glass shadow
                        videos.each((k, video) => {
                            if (!$(video).parents('.blur-content').length) {
                                // new Video($(video));
                                that.init($(video));
                            }
                        })

                    }

                }

            });
        });

    }

    init(container) {

        let that = this;

        // Save all videos in an array
        this.videos = [];

        // Create all video objects
        container.each((key, video) => {
            let initVideo = that.initVideo(video);
            that.videos.push(initVideo);
        });

        // Make variable global
        window.videos = this.videos;

    }


    initVideo(video) {

        let that = this;

        video = $(video);
        video.frame = video.find('.youtube__container');
        video.id = video.frame.data('video-id');
        video.poster = video.parent().find('.video__poster');
        video.posterIcon = video.parent().find('.video__btn');
        video.timeline = animateVideo(video);

        // Create iFrame and YouTube player
        video.player;
        video.playerID = video.find("#youtube-player--" + video.id).get(0);

        // Create YouTube Player Object
        video.player = new YT.Player(video.playerID, {
            width: '800',
            height: '450',
            videoId: video.id,
            host: 'https://www.youtube-nocookie.com',
            events: {
                'onReady': onPlayerReady,
            },
            playerVars: {
                rel: 0,
                showinfo: 0,
                modestbranding: 1
            },
            autoplay: 1,
            rel: 0,
        });

        // Callback: The API will call this function when the video player is ready.
        function onPlayerReady(event) {
            console.log("Player ready");
        }

        // Bind click event on video
        video.click(function () {
            if (!video.hasClass("active")) {
                video.timeline.play();
                video.player.playVideo();
            }
        });



        // Animate transition
        function animateVideo(video) {

            TweenLite.defaultEase = Power2.easeOut;
            let tl = gsap.timeline();

            tl
                .to(video, {
                    duration: 0.0001,
                    className: "+=video active"
                })
                .to(video.posterIcon, {
                    duration: 0.6,
                    autoAlpha: 0,
                    scale: "0.1"
                }, "a")
                .to(video.poster, {
                    duration: 0.6,
                    autoAlpha: 0
                }, "a+=0.3")
                .from(video.frame, {
                    duration: 0.6,
                    autoAlpha: 0
                }, "b")
                .pause();

            return tl;

        }

        return video;

    }

}

export default Video;
